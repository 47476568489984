.channel .col {
  padding: 0 10px !important;
}

.channel {
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
}

.channel img {
  width: 100%;
  margin-bottom: -3px;
}

.channel.disabled {
  border: 3px solid rgba(77, 77, 77, 0.3);
  cursor: not-allowed;
}

.channel.disabled img {
  opacity: 0.5;
}

.channel .check {
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
}

.channel.selected {
  border-color: #FF6915;
}

.channel.selected .check{
  display: inline-block;
}
