.conclusion-msg {
	text-align: center;
	font-size: 30px;
	width: 574px;
	margin: auto;
	margin-top: 150px;
	font-weight: 900;
}

.whatsapp {
	text-align: center;
	margin-top: 40px;
}

.whatsapp-button {
	background-color: #de552a;
	color: white;
	font-size: 14px;
	font-weight: bold;
	padding: 10px 20px;
	border-radius: 20px;
	border-color: #de552a;
}

.voltar {
	text-align: center;
	margin-top: 40px;
}

.voltar-button {
	background-color: #2F3134;
	color: white;
	font-size: 14px;
	font-weight: bold;
	padding: 10px 20px;
	border-radius: 20px;
	border-color: #2F3134;
}