.main {
  padding: 40px 50px;
  margin: 0 auto;
  width: 85%;
}

@media screen and (max-width: 600px) {
  .main {
    padding: 30px 20px;
  }
}

.main p{
  text-align: justify;
  font-size: 0.9rem;
  text-indent: 15px;
}

.main h1 {
  font-weight: 800;
  font-size: 23px;
  text-align: center;
  margin: 10px 0 0;
  text-transform: uppercase;
  background: linear-gradient(to right, #ff4a5d 0%, #ff6915 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.main h2 {
  font-weight: normal;
  font-size: 17px;
  text-align: center;
  color: #000000;
  margin: 12px 0 0;
}

.main h3 {
  font-weight: 700;
  font-size: 11px;
  color: #2F3134;
  margin: 35px 10px 5px;
  text-transform: uppercase;
}

.main hr{
  width: 100%;
  border-top: 1px solid #D6D6D6;
  margin-top: 5px;
}

.main .recaptcha{
  width: 302px;
  margin: auto;
}

.main .enviar{
text-align: center;
}

.main .text-left {
  text-align: left;
}

.main .margin-top {
  margin-top: 100px;
}

@media screen and (max-width: 600px) {
  .main h1 {
    margin: 0 0 0;
    font-size: 22px;
  }

  .main h2 {
    margin: 20px 0 0;
    font-size: 20px;
    display: none;
  }

  .main h3 {
    margin: 20px 0 0;
    font-size: 18px;
  }

  .main .margin-top {
    margin-top: 30px;
  }
}

.main .channels {
  margin: 35px auto 5rem;
  max-width: 575px;
  display: flex;
}

.main .channels .column {
  padding: 0 10px !important;
  margin: 0 10px 0 10px;
}

@media screen and (max-width: 600px) {
  .main .channels {
    margin: 25px auto 0;
    flex-direction: column;
    max-width: 170px;
  }

  .main .channels .column {
    margin: 10px auto;
    padding: 0 5px !important;
  }
}

.main .btn {
  background: #fc652d;
  border: none;
  border-radius: 10px;
  color: #ffffff;
  font-weight: bold;
  font-size: 24px;
  padding: 10px 40px;
  display: block;
  margin: 20px 0 0;
  cursor: pointer;
}

.main .btn.btn-continue {
  margin: 95px auto 0;
  padding: 10px 135px;
  text-transform: uppercase;
}

.main .btn:hover {
  background: #ca5124;
}

.main .btn:focus {
  outline-color: #ca5124 !important;
}

@media screen and (max-width: 500px) {
  .main .btn {
    padding: 10px 5px;
    width: 100%;
    margin: 15px auto 0;
    font-size: 21px;
  }

  .main .btn.btn-continue {
    margin: 35px auto 0;
    padding: 10px 5px;
  }
}

.main .message {
  font-size: 14px;
  text-align: center;
  color: #4d4d4d;
  padding: 0;
  box-sizing: border-box;
  margin: 10px 0;
}

.main .message a {
  color: #4d4d4d;
  text-decoration: underline;
}

.legal-message {
  margin-top: 30px;
}

.legal-button {
  background-image: linear-gradient(to right, #ff4a5d 0%, #ff6915  100%);
  font-weight: 700;
  border: none;
  color: #fff;
  border-radius: 8px;
  padding: 15px 25px;
  margin-top: 30px;
  cursor: pointer;
}

.button{
 text-align: center;
}


.legal-form-content {
  width: 100%;
  margin-top: 30px;
}

.legal-form-label-100 {
  width: 100%;
  font-size: 0.88em;
  color: #55575B;
  margin-left: 0.5rem;
}

.legal-input-100 {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 15px;
  height: 26px;
  border: 1px solid #cacaca;
}

.legal-left-50 {
  float: left;
  width: 40%;
}


.legal-left-60 {
  float: left;
  width: 60%;
}

.legal-right-40 {
  float: left;
  width: 38%;
  margin-left: 2%;
}

.legal-right-50 {
  float: left;
  width: 58%;
  margin-left: 2%;
}

.legal-form-button {
  background-color: #de552a;
  border: none;
  color: #fff;
  border-radius: 10px;
  height: 44px;
  width: 146px;
  margin-top: 30px;
  cursor: pointer;
}

.legal-textarea-100 {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #cacaca;
}

@media screen and (max-width: 500px) {
  .legal-form-content {
    width: 100%;
  }

  .legal-left-60 {
    width: 100%;
  }
  
  .legal-right-40 {
    width: 100%;
    margin-left: 0%;
  }

  .legal-left-50 {
    width: 100%;
  }

  .legal-right-50 {
    float: left;
    width: 100%;
    margin-left: 0%;
  }

  
}