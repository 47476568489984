/* Header */

.app .header {
  background-color: #ffffff;
  width: 100%;
}

.app .header hr{
  width: 85%;
  margin : auto;
  border-top: 1px solid #D6D6D6;
}

.app .header  a{
  margin: 5px 0 5px 15px;
  text-decoration: none;
  font-size: 0.9em;
  font-weight: 700;
}

.app .header .links .santa{
  color: #3d92b8;
}

.app .header .links .an{
  color: #145187;
}

.app .header .links .dc{
  color: rgba(1, 116, 241, 1);
}

.app .header .links .hora{
  color: #35991F;
}

.app .header .assine a{
  background: linear-gradient(to right, #ff4a5d 0%, #ff6915 100%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
}

.app .header .container {
  margin: 0 auto;
  box-sizing: border-box;
  padding: 14px 25px;
  position: relative;
  justify-content: space-around;
  display: flex;
  max-width: 1200px;
  width: 85%;
}

@media screen and (max-width: 700px) {
  .app .header .container {
    padding: 14px 15px;
    width: 100%;
  }
}

.app .header .logo svg {
  margin-top: 1em;
  fill: #2F3134;
  height: 30px;
}

.app .header .links {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;
}

/* End Header */

/* Footer */

.app .footer {
  text-align: center;
  padding: 30px;
  box-sizing: border-box;
}

.app .footer img {
  max-height: 33px;
  max-width: 95%;
  margin: 5px auto 30px;
}

.app .footer h1,
.app .footer p {
  color: #4d4d4d;
  font-size: 13px;
  font-weight: normal;
  margin: 0;
}

@media screen and (max-width: 700px) {
  .app .footer {
    padding: 10px;
  }

  .app .footer img {
    margin: 55px auto 25px;
  }

  .app .header hr{
   width: 110%;
  }

}

@media screen and (max-width: 350) {
  .links{
    display: none;
  }
}

/* End Footer */

#cta-button-mobile {
  display: none;
}

@media screen and (max-width: 600px) {
  #whatsapp {
    float: none;
    margin-right: unset;
    align-items: center;
    display: flex;
  }
  .whatsappbutton {
    margin: 0 auto;
    min-width: 210px;
  }
  .whatsappbutton img {
    float: left;
  }
  #cta-button-desk {
    display: none;
  }
  #cta-button-mobile {
    display: block;
  }
}
